import styled from "styled-components";

export const Root = styled.button`
    display: flex;
    width: ${props => props.width};  
    align-items: center;
    justify-content: space-between;
    padding: 15px;
    background: blue;
    border-radius: 50px;
    text-align: center;
    color: white;
    font-weight: bold;
    letter-spacing: 0.05em;
    /* width: 100%; */
    text-transform: uppercase;
    z-index: 1;
    position: relative;
    border: none;
    cursor: pointer;
    background: ${({ theme }) => theme.primaryGradient};
    transition: all 0.4s;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    outline: none;
      -webkit-box-shadow: 0px 9px 13px -6px rgba(57, 166, 233, 1);
      -moz-box-shadow: 0px 9px 13px -6px rgba(57, 166, 233, 1);
      box-shadow: 0px 9px 13px -6px rgba(57, 166, 233, 1);
    &:hover {
        opacity: 0.9;
        &::after {
            filter: blur(6px);
        }
    }
  
    &:hover:active::after {
        filter: blur(10px);
    }
    &:disabled {
        opacity: 0.4;
        cursor: not-allowed;
    }
`;
