import React from 'react';
import styled from 'styled-components';
import UploadDocsIcon from "../Icons/components/UploadDocsIcon"
import { toast } from 'react-toastify';

const DropBoxWrapper = styled.div`
   max-width: 570px;
   height: 150px;
   position: relative;
   display: block;
   width: 100%;
   margin-bottom:20px;
`

const Input = styled.input`
  width: 100%;
  height: 100%;
  position: relative;
  border-radius: 20px;
  cursor: pointer;
  opacity: 0;
  z-index: 100;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: white;
  }

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: transparent;
    z-index: 90;
    border-radius: 40px;
    border: 2px dotted ${({ theme }) => theme.primary};
  }
`

const DropBoxContent = styled.div`
    color: white;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`;

const Title = styled.h4`
    color: black;
`;
const FileName = styled.span`
    color: black;
    margin-top: 5px;
`;

const DropBox = ({ props }) => {
    const acceptableFormats = ['image/png', 'image/jpg', 'image/jpeg', 'application/pdf', 'image/heic'];
    // const maxSize = 5242880 // bytes sizes
    // const maxSize = 15728640; // bytes sizes
    const maxSize = 10485760; // bytes sizes

    const convertSize = (bytes) => {
        let sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
        if (bytes === 0) return false;
        let i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)));
        if (i === 0) return bytes + ' ' + sizes[i];
        return (bytes / Math.pow(1024, i)).toFixed(1) + ' ' + sizes[i];
    };


    const validateFiles = (e) => {
        const filesArray = Array.from(e.target.files);
        // eslint-disable-next-line
        const newFilesArray = filesArray.filter(file => {
            if (!acceptableFormats.includes(file.type)) {
                toast.warning(`Format pliku ${file.name} nie jest dozwolony`)
            } else if (file.size > maxSize) {
                toast.warning(`Plik ${file.name} jest za duży. Maksymalny format pliku to ${convertSize(maxSize)}`)
            }
            else {
                return file
            }
        })
        props.field.onChange(newFilesArray)
    }

    const handleChange = (e) => {
        validateFiles(e)
    }

    return (
        <DropBoxWrapper  >
            <Input
                type='file'
                multiple
                onChange={handleChange}
            />
            <DropBoxContent>
                <UploadDocsIcon width='50px' height='50px' style={{ marginBottom: '20px' }} />
                <Title>Dopuszczalne formaty to: png, jpg, pdf</Title>
                {
                    props?.field?.value &&
                    Object.values(props.field.value).map((item, index) => (
                        <FileName key={index}>{item.name}</FileName>
                    ))
                }
            </DropBoxContent>
        </DropBoxWrapper>
    )
};

export default DropBox;
