import { useMutation, useQuery } from "react-query";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import styled from "styled-components";
import Button from "../../../components/Buttons/Button";
import CardIcon from "../../../components/Icons/components/CardIcon";
import PaymentCardIcon from "../../../components/Icons/components/PaymentCardIcon";
import Loader from "../../../components/Loader/Loader";
import { selectTransactionId } from "../../../features/transactionSlice";
import Step6Service from "../../../services/Step6Service";
import { getDataFromAgreementNumber } from "../../../utils/functions";
import InputDescription from "../components/InputDescription";
// import RentLayout from "../components/RentLayout";
import RentTitle from "../components/RentTitle";
import { selectMobile } from '../../../features/appSlice'
import FlexBox from "../../../components/FlexBox/FlexBox";
import { useParams } from "react-router-dom";
import RentLayout from "../../../views/RentSteps/components/RentLayout";
import przelewy24 from './przelewy24.jpg'

const Subtitle = styled.div`
    margin: -20px 0 32px;
    line-height: 18px;
    font-size: 17px;
`;

const Flex = styled.div`
    display: flex;
    align-items: ${props => props.isMobile ? 'center' : 'left'};
    text-align: ${props => props.isMobile ? 'center' : 'left'};
    justify-content: space-between;
    line-height: 18px;
    border-bottom: ${({ border }) => (border ? "1px solid #798090" : "none")};
`;
/*const Delivery = styled(Flex)`
    margin-top: 28px;
    padding-top: 0 !important;
`;*/

const CostsContainer = styled.div`
    ${props => !props.isMobile && 'flex: 1;'}
    margin-top: 40px;
    font-size: 17px;
    & > div:first-child {
        margin-bottom: 10px;
    }
    ${Flex} {
        padding: 10px 0;

        & > span:first-child {
            max-width: 50%;
        }
    }
`;
const RecurringContainer = styled(CostsContainer)`
    ${props => !props.isMobile && 'flex: 1;'}
    margin-top: 40px;
    & > div:first-child {
        margin-bottom: 10px;
    }
`;

const Total = styled.div`
    padding: 18px 0;
    background-color: #f7f7f7;
    font-size: 18px;
    text-align: right;
`;

const MasterCardContainer = styled.div`
    margin: ${props => props.isMobile ? '20px 0 30px' : '0'};
    svg {
        width: 69px;
        height: 44px;
    }
`;

const Wrapper = styled.div`
    ${props => !props.isMobile && 'text-align: center;'}
`;

const RentTitleDesktop = styled.h3`
    margin: 28px 0 30px;
    font-size: 28px;
    line-height: 26px;
`;

const Przelewy24 = styled.img`
    width: 100%;
`


const OnlinePaymentAgain = ({ navProps }) => {

    const { schedule_id } = useParams();

    const isMobile = useSelector(selectMobile)
    const transactionId = useSelector(selectTransactionId);

    const { data, isLoading: isGettingData } = useQuery(
        "payment-summary",
        Step6Service.getData(transactionId)
    );

    const {
        mutateAsync: registerPayment,
        isLoading: isGeneratingPayment,
    } = useMutation(schedule_id && parseInt(schedule_id) > 0 ?
        Step6Service.registerExtraTransaction(transactionId, schedule_id) :
        Step6Service.registerTransaction(transactionId), {
        onError: () => {
            toast.error("Kod jest nieprawidłowy");
        },
    });

    const pay = async () => {

        const { link } = await registerPayment();
        window.location.href = link;
    };

    if (isGettingData || isGeneratingPayment) {
        return <Loader fixed />;
    }
    return (
        <RentLayout showNav={false} showSteps={false}>
            <Wrapper isMobile={isMobile} >
                {
                    isMobile ? <RentTitle>Płatność cykliczna online</RentTitle> : <RentTitleDesktop>Płatność cykliczna online</RentTitleDesktop>
                }

                <Subtitle>
                    <div>
                        <strong>dla umowy nr {data?.agreement_number}</strong>
                    </div>
                    {data && (
                        <div>
                            {`z dnia ${getDataFromAgreementNumber(
                                data?.agreement_number
                            )}`}
                        </div>
                    )}
                </Subtitle>
            </Wrapper>
            <FlexBox flexDirection={isMobile ? 'column' : 'row'} alignItems='initial' justifyContent="initial" gap='50px'>
                <CostsContainer isMobile={isMobile}>
                    <Flex>
                        {
                            isMobile ? 'Twoja dzisiejsza płatność' : <strong>Twoja dzisiejsza płatność</strong>
                        }
                    </Flex>
                    {schedule_id && parseInt(schedule_id) === 0 &&
                        <Flex border>
                            <span>Rata za pierwszy miesiąc najmu:</span>
                            <strong>{data?.gross_month_cost} zł</strong>
                        </Flex>
                    }
                    {schedule_id && parseInt(schedule_id) === 0 &&
                        <>
                            <Flex border>
                                <span>Jednorazowa kaucja zwrotna:</span>
                                <strong>{data?.net_administrative_fee} zł</strong>
                            </Flex>
                            <Total>
                                <Flex>
                                    <span>Płatność łącznie</span>
                                    <div>
                                        <strong>{data?.first_payment} zł</strong> brutto
                                    </div>
                                </Flex>
                            </Total>
                        </>
                    }
                    {schedule_id && parseInt(schedule_id) > 0 &&
                        <>
                            <Total>
                                <Flex>
                                    <span>Płatność łącznie</span>
                                    <div>
                                        <strong>{data?.gross_month_cost} zł</strong> brutto
                                    </div>
                                </Flex>
                            </Total>
                        </>
                    }

                </CostsContainer>
                <RecurringContainer isMobile={isMobile}>
                    {schedule_id && parseInt(schedule_id) === 0 &&
                        <>
                            <Flex>
                                {
                                    isMobile ? 'Twoje płatności cykliczne ' : <strong>Twoje płatności cykliczne</strong>

                                }
                                <InputDescription description={"Płatności kolejnych rat najmu będą pobierane co miesiąc z Twojej karty płatniczej"} />
                            </Flex>
                            <Flex border>
                                <span>Miesięczna rata w kolejnych miesiącach najmu:</span>
                                <span>
                                    <strong>{data?.gross_month_cost} zł</strong> brutto
                                </span>
                            </Flex>
                            <Flex border>
                                <span>Minimalna liczba rat do opłacenia:</span>
                                <span>
                                    <strong>{data?.months}</strong> raty
                                </span>
                            </Flex>
                        </>
                    }
                    <FlexBox justifyContent="space-between" alignItems="flex-start" margin="60px 0 0 0" flexDirection="column">
                        <div>
                            <FlexBox margin="0 0 15px 0" fontSize="18px">
                                <strong>Metoda płatności cyklicznej:</strong>
                            </FlexBox>
                            {
                                !data?.payPerLink && <FlexBox alignItems="center">
                                    <FlexBox margin="0 10px 0 0">
                                        <PaymentCardIcon />
                                    </FlexBox>
                                    <FlexBox margin="0 20px 0 0">
                                        Płatność kartą
                                    </FlexBox>
                                    <MasterCardContainer isMobile={isMobile}>
                                        <CardIcon />
                                    </MasterCardContainer>
                                </FlexBox>
                            }
                            {
                                data?.payPerLink &&
                                <FlexBox alignItems="flex-start" flexDirection="column">
                                    <FlexBox margin="0 20px 10px 0">
                                        <PaymentCardIcon style={{marginRight: '5px'}}/> Płatność online przez link
                                    </FlexBox>
                                    <Przelewy24 src={przelewy24} alt='przelewy24' />
                                </FlexBox>
                            }
                        </div>
                        <div style={{width: '100%', marginTop: '20px'}}>
                            <Button onClick={pay}>Płacę</Button>
                        </div>
                    </FlexBox>
                </RecurringContainer>
            </FlexBox>
        </RentLayout >
    );
};

export default OnlinePaymentAgain;
