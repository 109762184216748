import React from 'react'
import { useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import styled from 'styled-components'
import Loader from '../../../components/Loader/Loader';
import ProductDetails from '../../../components/Product/ProductTitle/ProductDetails';
import ProductTitle from '../../../components/Product/ProductTitle/ProductTitle';

import {
    selectMainProduct,
    selectMinimalMonthsParsed,
    selectShopUuid,
} from "../../../features/basketSlice";
import ProductService from '../../../services/ProductsService';
import GalleryBox from './GalleryBox';



const MainProduct = () => {

    const mainProduct = useSelector(selectMainProduct);
    const shop_uuid = useSelector(selectShopUuid);
    const minimal_months = useSelector(selectMinimalMonthsParsed);

    const { data, isLoading, isSuccess } = useQuery(
        ["product", mainProduct?.id, shop_uuid, minimal_months],
        ProductService.getProductData(
            mainProduct?.id,
            shop_uuid,
            minimal_months
        ),
        {
            keepPreviousData: true
        }
    );

    return (

        <StyledMainProduct>
            {
                isLoading && <Loader />
            }
            {
                (isSuccess && data) && (
                    <>
                        <ProductTitle
                            label=""
                            name={mainProduct.name}
                            availabilityText={mainProduct.availabilityText}
                            availabilitySymbol={
                                mainProduct.availabilitySymbol
                            }
                        />
                        <ProductDetails
                            location={mainProduct.localizations[0].city}
                            condition={mainProduct.conditionText}
                            flexDirection='row'
                            flexWrap='wrap'
                            gap='1rem'
                        />
                        <GalleryBox photosArr={
                            [mainProduct.image_url,
                            ...mainProduct.imageUrls]}

                        />
                        <ProductDetails
                            description={mainProduct.description}
                            extraContent={
                                <ProductDetails
                                    price={mainProduct.formattedGrossPrice}
                                />
                            }
                        />
                    </>
                )
            }
        </StyledMainProduct>
    )
}

export default MainProduct

const StyledMainProduct = styled.div`
    background-color: ${props => props.theme.white};
    padding: 20px;
    border-radius: 20px;
`