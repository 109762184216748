// import { Link } from "react-router-dom";
import styled from "styled-components";
import Container from "../../components/Container/Container";
// import CloseIcon from "../../components/Icons/components/CloseIcon";
// import { Linkedin } from "../../components/Icons/Icons.stories";
import Layout from "../../components/Layout/Layout";
// import { useHistory } from "react-router";
import { useSelector } from "react-redux";
import { selectMobile } from '../../features/appSlice'
// import FacebookIcon from "../../components/Icons/socialsIcons/FacebookIcon";
// import LinkedinIcon from "../../components/Icons/socialsIcons/LinkedinIcon";

// const CloseContainer = styled.div`
//     color: ${({ theme }) => theme.primary};
//     margin-bottom: 18px;
//     text-align: right;
//     div {
//         cursor: pointer;
//         transition: all 0.4s;
//         &:hover {
//             opacity: 0.6;
//         }
//     }
// `;
const AboutG4RContainer = styled.div`
    padding: 18px 25px 45px;
    background: ${({ theme }) => theme.white};
`;
const Title = styled.div`
    font-size: 1.4285714285714286em;
    line-height: 26px;
    margin-bottom: 30px;
`;
const Description = styled.div`
    white-space: pre-wrap;
    line-height: 20px;
    ${props => !props.isMobile && 'flex: 1;'}
`;
const VideoContainer = styled.div`
margin: ${(props) => props.isMobile ? '40px -50px 40px;' : '0 0 0 10px;'}
${props => !props.isMobile && 'flex: 1;'}
    iframe {
    width: 100%;
}
`;
// const Footer = styled.footer`
//     padding: 0 25px;
//     display: flex;
//     align-items: flex-end;
//     ul {
//         flex: 1;
//         list-style: none;
//         line-height: 22px;
//     }
//     a {
//         text-decoration: underline;
//     }
// `;
// const SocialIcons = styled.div`
//     display: flex;
//     justify-content: space-between;
//     font-size: 30px;
//     & > div {
//         margin-top: 14px;
//     }
// `;

const FlexDiv = styled.div`
    display: flex;
`

// const ExtraInfo = styled.div`
// display: flex;
// justify-content: space-between;
// `

// const MiniBox = styled.div`
// text-align: center;
//     p:first-child {
//     margin-bottom: 20px;
// }
// `

// const LinksContainer = styled.div`
// margin: ${props => props.isMobile ? '30px 25px 0' : '0'};
//     a {
//     margin-bottom: 20px;
//     display: block;
// }`

const AboutG4R = () => {
    const isMobile = useSelector(selectMobile);
    // const history = useHistory();
    return (
        <Layout desktopTitle="O G4 Rent">
            <AboutG4RContainer>
                <Container>
                    {/* <CloseContainer>
                        <div onClick={() => history.goBack()}>
                            <CloseIcon width={30} height={30} />
                        </div>
                    </CloseContainer> */}
                    <Title>
                        <strong>O G4.Rent:</strong>
                    </Title>
                    <FlexDiv>
                        <Description isMobile={isMobile}>
                            <p>{`
Jesteśmy największą i najlepiej zaopatrzoną wypożyczalnią sprzętu muzycznego w Polsce! Szukasz nowego, markowego sprzętu muzycznego ? Wypożycz, przetestuj, korzystaj.A jeśli chcesz - wykup lub zwróć!

Gitary, instrumenty klawiszowe, nagłośnienie, sprzęt DJ i wiele innych kategorii sprzętu muzycznego. 

Wybieraj spośród setek modeli dostępnych w naszych sklepach partnerskich zlokalizowanych w całej Polsce.
`}</p>
                        </Description>
                        {
                            !isMobile && (
                                <VideoContainer isMobile={isMobile}>
                                    <iframe
                                        width="560"
                                        height="343"
                                        src="https://www.youtube.com/embed/9kZJLt23MDo"
                                        title="YouTube video player"
                                        frameBorder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowFullScreen
                                    ></iframe>
                                </VideoContainer>
                            )
                        }
                    </FlexDiv>
                    {/* {
                        !isMobile && (
                            <>
                                <Title isMobile={isMobile} style={{ marginTop: '60px' }}>
                                    <strong>Masz więcej pytań?</strong>
                                </Title>
                                <ExtraInfo>
                                    <LinksContainer>
                                        <Link
                                            to="//g4.rent/wp-content/uploads/OWN-G4.pdf"
                                            target="_blank"
                                        >
                                            Ogólne warunki najmu
                                        </Link>
                                        <Link
                                            to="//g4.rent/wp-content/uploads/Polityka-Prywatnosci-UniVibe.pdf"
                                            target="_blank"
                                        >
                                            Polityka prywatności
                                        </Link>
                                    </LinksContainer>
                                    <MiniBox>
                                        <p>Univibe Sp. z o.o.</p>
                                        <p>Sarmacka 1, 02-972 Warszawa</p>
                                    </MiniBox>
                                    <MiniBox>
                                        <p>NIP: 9512505831</p>
                                        <p>KRS: 0000853684</p>
                                    </MiniBox>
                                    <MiniBox>
                                        <p>Tel. 510 510 092</p>
                                        <p>Email: info@g4.rent</p>
                                    </MiniBox>
                                    <MiniBox>
                                        <p>Znajdź nas</p>
                                        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                            <Link to="#">
                                                <FacebookIcon fontSize='30px' />
                                            </Link>
                                            <Link to="#">
                                                <LinkedinIcon fontSize='30px' />
                                            </Link>
                                        </div>
                                    </MiniBox>
                                </ExtraInfo>
                            </>
                        )
                    } */}
                </Container>
                {
                    isMobile && (
                        <>
                            <VideoContainer isMobile={isMobile}>
                                <iframe
                                    width="560"
                                    height="315"
                                    src="https://www.youtube.com/embed/9kZJLt23MDo"
                                    title="YouTube video player"
                                    frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen
                                ></iframe>
                            </VideoContainer>
                            {/* <Footer>
                                <ul>
                                    <li>Univibe Sp. z o.o.</li>
                                    <li>Sarmacka 1, 02-972 Warszawa</li>
                                    <li>NIP: 9512505831</li>
                                    <li>KRS: 0000853684</li>
                                    <li>Tel. 510 510 092</li>
                                    <li>
                                        Email:{" "}
                                        <a href="mailto:info@g4.rent">info@g4.rent</a>
                                    </li>
                                </ul>
                                <div>
                                    Znajdź nas:
                                    <SocialIcons>
                                        <div>
                                            <Link to="//www.facebook.com/profile.php?id=100027268766958" target="_blank">
                                                <FacebookIcon />
                                            </Link>
                                        </div>
                                        <div>
                                            <Link to="//www.linkedin.com/company/gear4rent/?viewAsMember=true" target="_blank">
                                                <Linkedin />
                                            </Link>
                                        </div>
                                    </SocialIcons>
                                </div>
                            </Footer> */}
                        </>
                    )
                }

            </AboutG4RContainer>
        </Layout >
    );
};

export default AboutG4R;
