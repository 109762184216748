import { Controller, useForm } from "react-hook-form";
import React from "react";
import { useMutation } from "react-query";
import { useSelector } from "react-redux";
// import { Link } from "react-router-dom";
import styled from "styled-components";
import Button from "../../../components/Buttons/Button";
import Checkbox from "../../../components/Inputs/Checkbox";
import {
    selectCustomerType,
    selectTransactionId,
} from "../../../features/transactionSlice";
import Step3Service from "../../../services/Step3Service";
import RentLayout from "../components/RentLayout";
import RentSubtitle from "../components/RentSubtitle";
import RentTitle from "../components/RentTitle";
import { useHistory } from "react-router";
import { useMemo } from "react";
import { selectMobile } from '../../../features/appSlice'
import NavButton from "../../../components/Buttons/NavButton"
import { Link } from "react-router-dom";


const CheckboxDescription = styled.p`
    margin: 12px 0 16px;
    font-size: 12px;
`;
const ButtonContainer = styled.div`
    margin-bottom: 38px;
`;
// const CommentContainer = styled.div`
//     margin-bottom: 28px;
// `;
const RequiredIcon = styled.span`
    color: ${({ theme }) => theme.danger};
    margin: 0 2px;
`;
// const Links = styled.div`
//     padding: 26px 0 0;
//     a {
//         display: block;
//         margin-bottom: 20px;
//         text-decoration: underline;
//     }
// `;

const RentSubtitleDesktop = styled.div`
    font-size: 28px;
    font-weight: bold;
    text-align: center;
`;

const CONSENTS = {
    NATURAL_PERSON: [
        {
            id: "4",
            content:
                "Niniejszym udzielam UniVibe sp. z o.o. z siedzibą w Warszawie (KRS: 0000853684) pełnomocnictwa do wypełnienia i podpisania w moim imieniu upoważnienia do pozyskania z Biura Informacji Gospodarczej InfoMonitor S.A. z siedzibą w Warszawie przy ul. Zygmunta Modzelewskiego 77 dotyczących mnie informacji gospodarczych oraz do pozyskania za pośrednictwem BIG InfoMonitor S.A. danych gospodarczych z Biura Informacji Kredytowej S.A. i Związku Banków Polskich, w tym między innymi oceny punktowej (scoring), w celu ujawnienia ich Pełnomocnikowi w zakresie niezbędnym do dokonania oceny wiarygodności płatniczej i oceny ryzyka kredytowego. Powyższe upoważnienie zostanie udzielone na podstawie art. 24 ust. 1 ustawy z dnia 9 kwietnia 2010 roku o udostępnianiu informacji gospodarczych i wymianie danych gospodarczych (tj. Dz.U.2020 poz. 389 ze. Zm.) oraz na podstawie art. 105 ust. 4a i 4a1 ustawy z dnia 29 sierpnia 1997 roku – Prawo bankowe (tj. Dz.U.2019 poz. 2357 ze zm.) w związku z art. 13 ustawy o udostępnianiu informacji gospodarczych i wymianie danych gospodarczych, wyłącznie na rzecz: UniVibe sp. z o.o. z siedzibą w Warszawie (KRS: 0000853684). Powyższe upoważnienie może także upoważnić ww. przedsiębiorcę do pozyskania z BIG InfoMonitor S.A. informacji dotyczących składanych zapytań na mój temat do Rejestru BIG InfoMonitor S.A. w ciągu ostatnich 12 miesięcy. Niniejsze upoważnienie jest ważne przez okres 60 dni, a w przypadku ostatecznego zawarcia umowy najmu przez cały okres obowiązywania tej umowy.",
        },
    ],
    INDIVIDUAL_ENTREPRENEUR: [
        {
            id: 1,
            content:
                "Niniejszym udzielam UniVibe sp. z o.o. z siedzibą w Warszawie (KRS: 0000853684) pełnomocnictwa do wypełnienia i podpisania w moim imieniu upoważnienia do pozyskania z Biura Informacji Gospodarczej InfoMonitor S.A. z siedzibą w Warszawie przy ul. Zygmunta Modzelewskiego 77 dotyczących mnie informacji gospodarczych oraz do pozyskania za pośrednictwem BIG InfoMonitor S.A. danych gospodarczych z Biura Informacji Kredytowej S.A. i Związku Banków Polskich, w tym między innymi oceny punktowej (scoring), w celu ujawnienia ich Pełnomocnikowi w zakresie niezbędnym do dokonania oceny wiarygodności płatniczej i oceny ryzyka kredytowego. Powyższe upoważnienie zostanie udzielone na podstawie art. 24 ust. 1 ustawy z dnia 9 kwietnia 2010 roku o udostępnianiu informacji gospodarczych i wymianie danych gospodarczych (tj. Dz.U.2020 poz. 389 ze. zm.) oraz na podstawie art. 105 ust. 4a i 4a1 ustawy z dnia 29 sierpnia 1997 roku - Prawo bankowe (tj. Dz.U.2019 poz. 2357 ze zm.) w związku z art. 13 ustawy o udostępnianiu informacji gospodarczych i wymianie danych gospodarczych, wyłącznie na rzecz: UniVibe sp. z o.o. z siedzibą w Warszawie (KRS: 0000853684). Powyższe upoważnienie może także upoważnić ww. przedsiębiorcę do pozyskania z BIG InfoMonitor S.A. informacji dotyczących składanych zapytań na mój temat do Rejestru BIG InfoMonitor S.A. w ciągu ostatnich 12 miesięcy. Niniejsze upoważnienie jest ważne przez okres 60 dni, a w przypadku ostatecznego zawarcia umowy najmu przez cały okres obowiązywania tej umowy.",
        },
        {
            id: 2,
            content:
                "Niniejszym udzielam UniVibe sp. z o.o. z siedzibą w Warszawie (KRS: 0000853684) pełnomocnictwa do wypełnienia i podpisania w moim imieniu jako przedsiębiorcy prowadzącego działalność gospodarczą, upoważnienia do pozyskania za pośrednictwem Biura Informacji Gospodarczej InfoMonitor S.A. z siedzibą w Warszawie przy ul. Zygmunta Modzelewskiego 77 danych gospodarczych związanych z prowadzoną przeze mnie działalnością gospodarczą z Biura Informacji Kredytowej S.A. i Związku Banków Polskich w celu ujawnienia ich Pełnomocnikowi w zakresie niezbędnym do dokonania oceny wiarygodności płatniczej i oceny ryzyka kredytowego. Powyższe upoważnienie zostanie udzielone na podstawie art. 105 ust. 4a i 4a1 ustawy z dnia 29 sierpnia 1997 roku - Prawo bankowe (tekst jednolity Dz.U.2019 poz. 2357 ze zm.) w związku z art. 13 ustawy z dnia 9 kwietnia 2010 roku o udostępnianiu informacji gospodarczych i wymianie danych gospodarczych (tj. Dz.U.2020 poz. 389 ze zm.), wyłącznie na rzecz: UniVibe sp. z o.o. z siedzibą w Warszawie (KRS: 0000853684). Powyższe upoważnienie może także upoważnić ww. przedsiębiorcę do pozyskania z BIG InfoMonitor S.A. informacji dotyczących składanych zapytań na temat reprezentowanego przez nas podmiotu do Rejestru BIG InfoMonitor S.A. w ciągu ostatnich 12 miesięcy. Niniejsze upoważnienie jest ważne przez okres 60 dni, a w przypadku ostatecznego zawarcia umowy najmu przez cały okres obowiązywania tej umowy.",
        },
    ],
    LEGAL_PERSON: [
        {
            id: 3,
            content:  "Niniejszym udzielam UniVibe sp. z o.o. z siedzibą w Warszawie (KRS: 0000853684) pełnomocnictwa do wypełnienia i podpisania w imieniu reprezentowanego przeze mnie podmiotu upoważnienia do pozyskania za pośrednictwem Biura Informacji Gospodarczej InfoMonitor S.A. z siedzibą w Warszawie przy ul. Zygmunta Modzelewskiego 77 danych gospodarczych z Biura Informacji Kredytowej S.A. i Związku Banków Polskich, w celu ujawnienia ich Pełnomocnikowi w zakresie niezbędnym do dokonania oceny wiarygodności płatniczej i oceny ryzyka kredytowego. Powyższe upoważnienie zostanie udzielone na podstawie art. 105 ust. 4a i 4a1 ustawy z dnia 29 sierpnia 1997 roku – Prawo bankowe (tekst jednolity Dz.U.2019 poz. 2357 ze zm.) w związku z art. 13 ustawy z dnia 9 kwietnia 2010 roku o udostępnianiu informacji gospodarczych i wymianie danych gospodarczych (tj. Dz.U.2020 poz. 389 ze zm.), wyłącznie na rzecz: UniVibe sp. z o.o. z siedzibą w Warszawie (KRS: 0000853684). Powyższe upoważnienie może także upoważnić ww. przedsiębiorcę do pozyskania z BIG InfoMonitor S.A. informacji dotyczących składanych zapytań na temat reprezentowanego przez nas podmiotu do Rejestru BIG InfoMonitor S.A. w ciągu ostatnich 12 miesięcy. Niniejsze upoważnienie jest ważne przez okres 60 dni, a w przypadku ostatecznego zawarcia umowy najmu przez cały okres obowiązywania tej umowy.",
        },
        {
            id: 1,
            content:
                "Niniejszym udzielam UniVibe sp. z o.o. z siedzibą w Warszawie (KRS: 0000853684) pełnomocnictwa do wypełnienia i podpisania w moim imieniu upoważnienia do pozyskania z Biura Informacji Gospodarczej InfoMonitor S.A. z siedzibą w Warszawie przy ul. Zygmunta Modzelewskiego 77 dotyczących mnie informacji gospodarczych oraz do pozyskania za pośrednictwem BIG InfoMonitor S.A. danych gospodarczych z Biura Informacji Kredytowej S.A. i Związku Banków Polskich, w tym między innymi oceny punktowej (scoring), w celu ujawnienia ich Pełnomocnikowi w zakresie niezbędnym do dokonania oceny wiarygodności płatniczej i oceny ryzyka kredytowego. Powyższe upoważnienie zostanie udzielone na podstawie art. 24 ust. 1 ustawy z dnia 9 kwietnia 2010 roku o udostępnianiu informacji gospodarczych i wymianie danych gospodarczych (tj. Dz.U.2020 poz. 389 ze. zm.) oraz na podstawie art. 105 ust. 4a i 4a1 ustawy z dnia 29 sierpnia 1997 roku - Prawo bankowe (tj. Dz.U.2019 poz. 2357 ze zm.) w związku z art. 13 ustawy o udostępnianiu informacji gospodarczych i wymianie danych gospodarczych, wyłącznie na rzecz: UniVibe sp. z o.o. z siedzibą w Warszawie (KRS: 0000853684). Powyższe upoważnienie może także upoważnić ww. przedsiębiorcę do pozyskania z BIG InfoMonitor S.A. informacji dotyczących składanych zapytań na mój temat do Rejestru BIG InfoMonitor S.A. w ciągu ostatnich 12 miesięcy. Niniejsze upoważnienie jest ważne przez okres 60 dni, a w przypadku ostatecznego zawarcia umowy najmu przez cały okres obowiązywania tej umowy.",
        },
    ],
};

const OnlineApplication = ({ navProps }) => {
    const customerType = useSelector(selectCustomerType);
    const transactionId = useSelector(selectTransactionId);
    const { mutateAsync: makeAnApplication, isLoading } = useMutation(
        Step3Service.saveStep3(
            customerType.toLowerCase().replace("_", "-"),
            transactionId
        )
    );
    const history = useHistory();
    const { control, handleSubmit, watch } = useForm();

    const _values = watch();
    const isValid = useMemo(() => {
        if (customerType === "NATURAL_PERSON") {
            return _values[4] === true && _values.c29;
        }
        if (customerType === "INDIVIDUAL_ENTREPRENEUR") {
            return _values[1] === true && _values[2] === true && _values.c29;
        }
        if (customerType === "LEGAL_PERSON") {
            return _values[3] === true  && _values.c29;
        }
        return false;
    }, [_values, customerType]);

    const onSubmit = async (values) => {

        const newValues = {}
        for (let key in values) {
            if (key !== 'c29') {
                newValues[key] = values[key]
            }
        }

        const payload = { consents: [] };
        for (let i in newValues) {
            payload.consents.push({ consent_id: i, checked: values[i] });
        }

        payload.c29 = values.c29

        await makeAnApplication(payload);
        history.push("/rent/g4-confirmation");
    };

    const isMobile = useSelector(selectMobile)
    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <RentLayout showNav={false} {...navProps} showLoader={isLoading}>
                {isMobile && <RentTitle>Dane finansowe:</RentTitle>}
                {
                    isMobile ? (
                        <RentSubtitle>
                            Zaakceptuj zgody i pełnomocnictwa oraz złóż wniosek
                        </RentSubtitle>
                    ) : (
                        <RentSubtitleDesktop>
                            Zaakceptuj zgody i pełnomocnictwa oraz złóż wniosek
                        </RentSubtitleDesktop>
                    )
                }
                {customerType &&

                    CONSENTS[customerType].map((consent, index) => (
                        <React.Fragment key={index}>
                            <Controller
                                control={control}
                                name={`[${consent.id}]`}
                                defaultValue={false}
                                render={({ field: { onChange, value } }) => (
                                    <>
                                        <Checkbox
                                            onChange={onChange}
                                            value={value}
                                        />
                                        <CheckboxDescription>
                                            {!(customerType === 'LEGAL_PERSON' && consent.id === 1) && <RequiredIcon>*</RequiredIcon>}
                                            {consent.content}
                                        </CheckboxDescription>
                                    </>
                                )}
                            />
                        </React.Fragment>
                    ))}
                {customerType && (
                    <Controller
                    control={control}
                    name={`c29`}
                    defaultValue={false}
                    render={({ field: { onChange, value } }) => (
                        <>
                            <Checkbox
                                onChange={onChange}
                                value={value}
                            />
                            <CheckboxDescription>
                                <RequiredIcon>*</RequiredIcon>
                                {`${'Akceptuję '}`}

                                <Link
                                style={{color: '#00ACED'}}
                                to="//g4.rent/wp-content/uploads/Polityka-Prywatnosci-UniVibe.pdf"
                                target="_blank"
                                >
                                politykę prywatności
                            </Link>

                            {`${' oraz '}`}
                            <Link
                                style={{color: '#00ACED'}}
                                to="//g4.rent/wp-content/uploads/Regulamin-swiadczenia-uslug-UniVibe-droga-elektroniczna.pdf"
                                target="_blank"
                            >
                                {`${' regulamin '}`}
                            </Link>
                            </CheckboxDescription>
                        </>
                    )}
                />
                )}

                {/* <CommentContainer>
                    <RequiredIcon>*</RequiredIcon>
                    Wyrażenie zgody jest konieczne dla rozpatrzenia wniosku o
                    wynajem
                </CommentContainer> */}
                {
                    isMobile ? (
                        <ButtonContainer>
                            <Button type="submit" disabled={isLoading || !isValid}>
                                Złóż wniosek
                            </Button>
                        </ButtonContainer>
                    ) : (
                        <NavButton
                            // backLink={"/rent/financial-data"}
                            backLink={customerType === "LEGAL_PERSON" ? "/rent/contract-data" : "/rent/financial-data"}
                            submit
                            disabled={isLoading || !isValid}
                        />
                    )
                }
                {/* {
                    isMobile && (
                        <>
                            <Footer />
                            <Links>
                                <div>
                                    <Link
                                        to="//g4.rent/wp-content/uploads/OWN-G4R.pdf"
                                        target="_blank"
                                    >
                                        Ogólne warunki najmu
                                    </Link>
                                </div>
                                <div>
                                    <Link
                                        to="//g4.rent/wp-content/uploads/Polityka-Prywatnosci-UniVibe.pdf"
                                        target="_blank"
                                    >
                                        Polityka prywatności
                                    </Link>
                                </div>
                            </Links>
                        </>
                    )
                } */}

            </RentLayout>
        </form>
    );
};

export default OnlineApplication;
