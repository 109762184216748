import React from 'react'
import styled from 'styled-components'

const StyledTile = styled.div`
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;

const Box = styled.div`
    ${props => props.active ? `border 4px solid #0486D6` : `border 1px solid ${props.theme.grey}`};
    transition: all ease-in-out 0.3s;
    width: 164px;
    height: 164px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 40px;
    margin-bottom: 20px;
`;

const Description = styled.p`
    font-size: 18px;
    font-weight: bold;
`;

const Tile = ({ icon, description, ...props }) => {
    return (
        <StyledTile {...props}>
            <Box {...props}>
                {icon}
            </Box>
            {
                description && <Description>{description}</Description>
            }
        </StyledTile>
    )
}

export default Tile
