import { useMutation, useQuery } from "react-query";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import styled from "styled-components";
import Button from "../../../components/Buttons/Button";
import Loader from "../../../components/Loader/Loader";
import { selectTransactionId } from "../../../features/transactionSlice";
import Step6Service from "../../../services/Step6Service";
import { getDataFromAgreementNumber } from "../../../utils/functions";
import InputDescription from "../components/InputDescription";
import RentLayout from "../components/RentLayout";
import RentTitle from "../components/RentTitle";
import { selectMobile } from '../../../features/appSlice'
import FlexBox from "../../../components/FlexBox/FlexBox";
import przelewy24 from './przelewy24.jpg'
import CardIcon from "../../../components/Icons/components/CardIcon";
import PaymentCardIcon from "../../../components/Icons/components/PaymentCardIcon";

const Flex = styled.div`
    display: flex;
    align-items: ${props => props.isMobile ? 'center' : 'left'};
    text-align: ${props => props.isMobile ? 'center' : 'left'};
    justify-content: space-between;
    line-height: 18px;
    border-bottom: ${({ border }) => (border ? "1px solid #798090" : "none")};
`;

const CostsContainer = styled.div`
    ${props => !props.isMobile && 'flex: 1;'}
    margin-top: 40px;
    font-size: 17px;
    & > div:first-child {
        margin-bottom: 10px;
    }
    ${Flex} {
        padding: 10px 0;

        & > span:first-child {
            max-width: 50%;
        }
    }
`;
const RecurringContainer = styled(CostsContainer)`
    ${props => !props.isMobile && 'flex: 1;'}
    margin-top: 40px;
    & > div:first-child {
        margin-bottom: 10px;
    }
`;
const PaymentMethodContainer = styled(CostsContainer)`
    margin-top: 10px;
    margin-bottom: 0px;
`;
const Total = styled.div`
    padding: 18px 0;
    background-color: #f7f7f7;
    font-size: 18px;
    text-align: right;
`;

const MasterCardContainer = styled.div`
    svg {
        width: 69px;
        height: 44px;
    }
`;

const Wrapper = styled.div`
    ${props => !props.isMobile && 'text-align: center;'}
`;

const RentTitleDesktop = styled.h3`
    margin: 28px 0 30px;
    font-size: 28px;
    line-height: 26px;
`;

const ProductListContainer = styled(CostsContainer)`
    
`;

const StatementContainer = styled.div`
   font-size: 13px;
   text-align: left;
   border-radius: 20px;
   margin: 20px 0;
 `;

const Przelewy24 = styled.img`
    width: 100%;
`


const OnlinePayment = ({ navProps }) => {
    const isMobile = useSelector(selectMobile)
    const transactionId = useSelector(selectTransactionId);

    const { data, isLoading: isGettingData } = useQuery(
        "payment-summary",
        Step6Service.getData(transactionId)
    );

    const {
        mutateAsync: registerPayment,
        isLoading: isGeneratingPayment,
    } = useMutation(Step6Service.registerTransaction(transactionId), {
        onError: () => {
            toast.error("Kod jest nieprawidłowy");
        },
    });

    const pay = async () => {
        const { link } = await registerPayment();

        window.location.href = link;
    };

    if (isGettingData || isGeneratingPayment) {
        return <Loader fixed />;
    }
    return (
        <RentLayout showNav={false} {...navProps}>
            <Wrapper isMobile={isMobile}>
                {
                    isMobile ? <RentTitle>Płatność cykliczna online</RentTitle> : <RentTitleDesktop>Płatność cykliczna online</RentTitleDesktop>
                }
            </Wrapper>
            <FlexBox flexDirection={'row'} alignItems='initial' justifyContent="initial">
                <ProductListContainer>
                    <Flex>
                        <strong>Zamówione produkty / usługi:</strong>
                    </Flex>
                    {
                        data && data.products.map((p, index) => (
                            <Flex border key={index}>
                                <span>{p.name}</span>
                            </Flex>
                        ))
                    }
                </ProductListContainer>
            </FlexBox>
            <FlexBox flexDirection={isMobile ? 'column' : 'row'} alignItems='initial' justifyContent="initial" gap='50px'>
                <CostsContainer isMobile={isMobile}>
                    <Flex>
                        <strong>Twoja dzisiejsza płatność</strong>
                    </Flex>
                    <Flex border>
                        <span>Rata za pierwszy miesiąc najmu:</span>
                        <strong>{data?.gross_month_cost} zł</strong>
                    </Flex>
                    <Flex border>
                        <span>Jednorazowa kaucja zwrotna:</span>
                        <strong>{data?.net_administrative_fee} zł</strong>
                    </Flex>
                    <Total>
                        <Flex>
                            <span>Płatność łącznie</span>
                            <div>
                                <strong>{data?.first_payment} zł</strong> brutto
                            </div>
                        </Flex>
                    </Total>
                </CostsContainer>
                <RecurringContainer isMobile={isMobile}>
                    <Flex>
                        <strong>Twoje płatności cykliczne</strong>
                        <InputDescription description={"Płatności kolejnych rat najmu będą pobierane co miesiąc z Twojej karty płatniczej. Pierwsza płatność cykliczna zostanie pobrana po upływie 30 dni od daty odbioru sprzętu."} />
                    </Flex>
                    <Flex border>
                        <span>Miesięczna rata w kolejnych miesiącach najmu:</span>
                        <span>
                            <strong>{data?.gross_month_cost} zł</strong> brutto
                        </span>
                    </Flex>
                    <Flex border>
                        <span>Minimalna liczba rat do opłacenia:</span>
                        <span>
                            <strong>{data?.months}</strong> raty
                        </span>
                    </Flex>
                    <StatementContainer>
                        Zamawiam i płacę pierwszą ratę z wraz kaucją, a także wyrażam zgodę na obciążanie mojej karty płatniczej kolejnymi ratami najmu zgodnie z umową nr {data?.agreement_number} z dnia   {data && (
                            <>
                                {`${getDataFromAgreementNumber(
                                    data?.agreement_number
                                )}`}
                            </>
                        )}
                    </StatementContainer>
                    {
                        !isMobile ? (
                            <>
                                <FlexBox justifyContent="space-between" alignItems="flex-start" flexDirection="column">
                                        {data?.payPerLink &&
                                                <Przelewy24 src={przelewy24} alt='przelewy24' />
                                        }
                                        {!data?.payPerLink &&
                                            <FlexBox flexDirection="row" alignItems={'center'}>
                                                <FlexBox margin="0 10px 0 0">
                                                    <PaymentCardIcon />
                                                </FlexBox>
                                                <FlexBox margin="0 20px 0 0">
                                                    Płatność kartą
                                                </FlexBox>
                                                <MasterCardContainer isMobile={isMobile}>
                                                    <CardIcon />
                                                </MasterCardContainer>
                                            </FlexBox>
                                        }
                                </FlexBox>
                                <FlexBox margin="15px 0 0 0">
                                    <Button onClick={pay}>Zamawiam i płacę</Button>
                                </FlexBox>
                            </>
                        ) : null
                    }
                </RecurringContainer>
            </FlexBox>
            {
                isMobile ? (
                    <>
                        <PaymentMethodContainer>
                            {!data?.payPerLink &&
                                <FlexBox flexDirection="row" alignItems={'center'}>
                                    <FlexBox margin="0 10px 0 0">
                                        <PaymentCardIcon />
                                    </FlexBox>
                                    <FlexBox margin="0 20px 0 0">
                                        Płatność kartą
                                    </FlexBox>
                                    <MasterCardContainer isMobile={isMobile}>
                                        <CardIcon />
                                    </MasterCardContainer>
                                </FlexBox>
                            }
                        </PaymentMethodContainer>
                        <MasterCardContainer isMobile={isMobile}>
                            {data?.payPerLink && <Przelewy24 src={przelewy24} alt='przelewy24' />}
                        </MasterCardContainer>
                        <footer>
                            <Button onClick={pay} style={{marginTop: '30px'}}>Zamawiam i płacę</Button>
                        </footer>
                    </>
                ) : null
            }
        </RentLayout >
    );
};

export default OnlinePayment;
