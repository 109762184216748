import React, { useState } from 'react';
import Layout from '../../components/Layout/Layout';
import styled from 'styled-components';
import DropBox from '../../components/Inputs/DropBox';
import { Controller, useForm } from 'react-hook-form';
import Button from '../../components/Buttons/Button';
import { useMutation, useQuery } from 'react-query';
import Step5Service from '../../services/Step5Service'
import OrderService from '../../services/OrderService'
import { toBase64 } from '../../utils/functions';
import { toast } from 'react-toastify';
import { useDispatch, useSelector } from 'react-redux';
import { setTransaction } from "../../features/transactionSlice";
import { useParams } from 'react-router-dom'
import { selectMobile } from "../../features/appSlice";
import Loader from '../../components/Loader/Loader';
import { useHistory } from 'react-router-dom';


const Card = styled.div`
    background-color: ${props => !props.isMobile && props.theme.white};
    padding: 25px;
    border-radius: 25px;
    text-align: center;
`

const Header = styled.header`
    font-size: 28px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 30px;
`
const Form = styled.form`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

const UploadAgreement = () => {
    const history = useHistory()
    const [globalLoader, setGlobalLoader] = useState(0)

    const isMobile = useSelector(selectMobile);
    const dispatch = useDispatch();
    const { token1, token2 } = useParams()
    const { handleSubmit, control } = useForm()

    const { data } = useQuery(['data', token1, token2], OrderService.uploadLogin(token1, token2), {
        onSuccess: (data) => {
            dispatch(setTransaction(data))
        }
    })

    const { mutate } = useMutation(
        Step5Service.sendAgreement(data?.transaction_id),
        {
            onSuccess: () => {
                toast.success("Pomyślnie przesłano umowę")
                setGlobalLoader(prev => prev - 1)
                globalLoader === 0 && history.push('/upload-confirmation')
            },
            onError: () => {
                toast.error("Ups... Coś poszło nie tak")
                setGlobalLoader(prev => prev - 1)
            }
        }
    )


    const onSubmit = async (data) => {
        await data.dropbox.forEach(async (file) => {
            let formData = new FormData();
            const base64 = await toBase64(file);
            formData.append("filename", file.name);
            formData.append("binaryStr", base64.split("base64,")[1]);
            await mutate(formData);
            setGlobalLoader(prev => prev + 1)
        })
    }


    const onError = () => {
        toast.warning("Dodaj plik przed wysłaniem")
    }


    return <div>
        <Layout showBasket={false} >
            {
                !!globalLoader && <Loader fixed={true} />
            }
            <Card>
                <Header>
                    Dokumenty dotyczące wniosku o wynajem
                </Header>
                <Form onSubmit={handleSubmit(onSubmit, onError)}>
                    <Controller
                        name='dropbox'
                        control={control}
                        defaultValue={[]}
                        rules={{ required: true }}
                        render={props => <DropBox props={props} />}
                    />
                    <Button type='submit' width={isMobile ? '100%' : 'initial'} onClick={() => false}>Wyślij dokumenty</Button>
                </Form>
            </Card>
        </Layout>
    </div>;
};

export default UploadAgreement;
