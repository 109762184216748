import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import CalendarIcon from "../../Icons/components/CalendarIcon";
import ClockIcon from "../../Icons/components/ClockIcon";
import CloseIcon from "../../Icons/components/CloseIcon";
import DownloadDocsIcon from "../../Icons/components/DownloadDocsIcon";
import QuestionMarkIcon from "../../Icons/components/QuestionMarkIcon";
import SettingsIcon from "../../Icons/components/SettingsIcon";
import SectionHeader from "../../SectionHeader/SectionHeader";
import Swiper from "../../Swiper/Swiper";

const Root = styled.div`
    margin: 40px -25px 30px;
    .swiper-container {
        padding: 0 25px;
    }
`;
const Title = styled.div`
    margin: 0 25px;
`;

const CardContainer = styled.div`
    background: ${({ theme }) => theme.white};
    border-radius: 25px;
    overflow: hidden;
`;
const CardHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: ${({ active }) => (!!active ? "all 0.4s" : "all 0.6s")};
    background: ${({ theme, active }) =>
        !!active ? theme.secondary : theme.white};
    padding: 20px 15px;
    color: ${({ theme, active }) => (!!active ? theme.white : theme.black)};
    cursor: ${({ active }) => (active ? "initial" : "pointer")};
`;
const CardHeaderIcon = styled.div`
    font-size: 40px;
    width: 60px;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 10px;
    background: ${({ theme, active }) => theme.secondary};
    color: ${({ theme, active }) => theme.white};
`;
const CardHeaderTitle = styled.div`
    flex: 1;
    padding: 0 18px;
    font-size: 1.2857142857142858em;
    line-height: 24px;
`;
const CardHeaderClose = styled.div`
    font-size: 30px;
    cursor: pointer;
`;
const CardHeaderQuestion = styled(CardHeaderClose)`
    color: #798090;
`;
const CardContent = styled.div`
    padding: 32px 25px 50px;
    line-height: 16px;
    min-height: 280px;
`;

const Card = ({ Title, Description, Icon, active, onClick, onClose }) => (
    <CardContainer onClick={onClick}>
        <CardHeader active={active !== null}>
            <CardHeaderIcon active={active}>{Icon}</CardHeaderIcon>
            <CardHeaderTitle>
                <strong style={{fontSize:"18px"}}>{Title}</strong>
            </CardHeaderTitle>
            {active !== null && (
                <CardHeaderClose
                    onClick={(e) => {
                        e.stopPropagation();
                        onClose();
                    }}
                >
                    <CloseIcon />
                </CardHeaderClose>
            )}
            {active === null && (
                <CardHeaderQuestion>
                    <QuestionMarkIcon />
                </CardHeaderQuestion>
            )}
        </CardHeader>
        {/* <Collapse isOpen={!!active} ActionComponent={() => <></>}> */}
        {active !== null && (
            <CardContent>
                <p style={{fontSize:"17px",lineHeight:"20px"}}>{Description}</p>
            </CardContent>
        )}
        {/* </Collapse> */}
    </CardContainer>
);

const WHY_WORTH_DATA = [
    {
        title: "Wykup w dowolnym momencie",
        description: `Gwarantujemy Ci możliwość wykupu wynajętego sprzętu w dowolnym momencie od rozpoczęcia umowy najmu. Nie płacisz za to żadnych dodatkowych prowizji, a jedynie cenę wykupu podaną w naszej ofercie. To fantastyczna opcja dla tych, którzy szukają konkretnego sprzętu, ale najpierw chcą go przetestować komfortowo w swoich warunkach bez presji czasu i otoczenia.`,
        icon: CalendarIcon,
    },
    {
        title: "Niskie miesięczne raty",
        description: `Twoja miesięczna rata najmu jest tym niższa, im dłuższy jest wybrany przez Ciebie minimalny okres wynajmu. Przy zawieraniu umowy płacisz tylko pierwszą ratę oraz jednorazową kaucję zwrotną. Bez żadnych ukrytych opłat, ani prowizji. Jeśli z góry zakładasz, że będziesz chciał(a) wykupić sprzęt, sprawdź opcję wykupu. Określona część każdej raty przeznaczona jest na wykup sprzętu, a po pewnym czasie, w zależności od wybranego minimalnego okresu najmu, możesz wykupić wynajmowany przez siebie sprzęt za symboliczne 1zł.`,
        icon: DownloadDocsIcon,
    },
    {
        title: "Elastyczny okres najmu",
        description: `Wypożyczasz na dowolny czas, jednak nie krótszy niż minimalny okres najmu wybrany w umowie. Po jego upływie możesz kontynuować wynajem na tych samych zasadach, aż do pełnej spłaty wypożyczonego sprzętu. Nie musisz nas o tym informować. Możesz także zwrócić wypożyczony sprzęt w dowolnym momencie po upływie minimalnego okresu najmu, a Twoja umowa najmu zakończy się. Pamiętaj także, że możesz wykupić wynajmowany sprzęt, także w trakcie trwania minimalnego okresu najmu.`,
        icon: ClockIcon,
    },
    {
        title: "Dodatkowe akcesoria i usługi",
        description: `Sprawdź atrakcyjną ofertę dodatkowych akcesoriów i usług rekomendowanych dla Twojego instrumentu, jak i innych, dostępnych w naszej bogatej ofercie. Dobierz pakiety konfiguracji czy instalacji sprzętu, dodatkowy serwis techniczny w trakcie najmu, naukę gry lub inne pakiety systematycznie rozbudowywane na potrzeby naszego programu i ciesz się w pełni nowym sprzętem muzycznym!`,
        icon: SettingsIcon,
    },
];

const WhyWorth = () => {
    const swiperRef = useRef(null);
    const [active, setActive] = useState(null);
    useEffect(() => {
        if (swiperRef.current) {
            swiperRef.current.enabled = active !== null;
            swiperRef.current.updateActiveIndex(active ? active : 0);
            swiperRef.current.update();
        }
    }, [active]);
    return (
        <div>
            <Root>
                <Title>
                    <SectionHeader>
                        Dlaczego warto wynająć sprzęt w G4.Rent?
                    </SectionHeader>
                </Title>
                <Swiper
                    slides={WHY_WORTH_DATA.map(
                        ({ title, description, icon }, index) => (
                            <Card
                                key={index}
                                Title={title}
                                Description={description}
                                Icon={icon()}
                                active={active}
                                onClick={() => setActive(index)}
                                onClose={() => setActive(null)}
                            />
                        )
                    )}
                    autoHeight={false}
                    isAbsoluteNavigation
                    direction={active !== null ? "horizontal" : "vertical"}
                    spaceBetween={10}
                    slidesPerView={active !== null ? 1 : 4}
                    showNavigation={active !== null}
                    enabled={active !== null ? true : false}
                    onSwiper={(swiper) => (swiperRef.current = swiper)}
                />
            </Root>
        </div>
    );
};

export default WhyWorth;
