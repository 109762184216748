import {useEffect, useState} from "react";
import { useQuery } from "react-query";
import { useDispatch } from "react-redux";
import { Redirect, useParams } from "react-router";
import Layout from "../../components/Layout/Layout";
import Loader from "../../components/Loader/Loader";
import {
    setMainProduct,
    setShopUuid,
    setMinimalMonths
} from "../../features/basketSlice";

// import {
//     reset,
// } from "../../features/transactionSlice";

import ProductService from "../../services/ProductsService";
import { clearData } from "../../utils/functions";


const ProductLoader = () => {
    const params = useParams();
    // console.log(params);
    const dispatch = useDispatch();
    // const mainProduct = useSelector(selectMainProduct);
    const [mainProduct, setProduct] = useState(null);
    const [notFound, setNotFound] = useState(false);
    const [notNotAvailable, setNotAvailable] = useState(false);



    useEffect(() => {
        clearData();
        // eslint-disable-line
    }, []);

    // if(params?.min_months) {
    //     dispatch(setMinimalMonths(Number(params?.min_months)))
    // }

    useQuery(
        ["main-product", params.product_code, params.shop_uuid],
        ProductService.getMainProductData(
            params.product_code,
            params.shop_uuid
        ),
        {
            onSuccess: (data) => {
                clearData();
                if (data.availabilitySymbol !== "OUT_OF_STOCK") {
                    dispatch(setShopUuid(params.shop_uuid));
                    dispatch(setMainProduct(data));
                    setProduct(data);

                } else {
                    setNotAvailable(true);
                }
            },
            onError: () => {
                setNotFound(true);
            },
        }
    );

    if (notNotAvailable) {
        return (
            <Layout>
                <h1>Produkt nie jest dostępny</h1>
            </Layout>
        );
    }

    if (notFound) {
        return (
            <Layout>
                <h1>Nie znaleziono partnera</h1>
            </Layout>
        );
    }

    if (mainProduct) {
        if(params?.basket) {
            let newMinMonths = 0
            if (params?.min_months === '1') {
                newMinMonths = 0
            }
            else if (params?.min_months === '3') {
                newMinMonths = 1
            }
            else if (params?.min_months === '6') {
                newMinMonths = 2
            }
            else if (params?.min_months === '12') {
                newMinMonths = 3
            }
            else if (params?.min_months === '24') {
                newMinMonths = 4
            }
            else {
                newMinMonths = 4
            }
            dispatch(setMinimalMonths(newMinMonths))
            return <Redirect to="/basket" />;
        }
        return <Redirect to="/" />;
    }
    return <Loader fixed />;
};

export default ProductLoader;
