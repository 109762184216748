import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { useParams } from "react-router";
import styled from "styled-components";
import Button from "../../components/Buttons/Button";
import DropBox from "../../components/Inputs/DropBox";
import Layout from "../../components/Layout/Layout";
import Loader from "../../components/Loader/Loader";
import OrderService from "../../services/OrderService";
import { toBase64 } from "../../utils/functions";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { useSelector } from "react-redux";
import { selectMobile } from "../../features/appSlice";

const Header = styled.header`
    font-size: 28px;
    font-weight: bold;
    text-align: center;
    margin-bottom:20px;
`

const Card = styled.div`
    background-color: ${props => !props.isMobile && props.theme.white};
    padding: 25px;
    border-radius: 25px;
    text-align: center;
`

const Form = styled.form`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`

const Desc = styled.p`
    font-size: 16px;
    margin-bottom: 20px; 
`;

const ConfirmDocuments = ({ uploadAction }) => {
    const isMobile = useSelector(selectMobile);
    const history = useHistory()
    const [globalLoader, setGlobalLoader] = useState(0)
    const { token1, token2 } = useParams();
    const [data, setData] = useState(null);

    const { mutate } = useMutation(OrderService.uploadLogin(token1, token2), {
        onSuccess: setData,
    });
    const {
        mutate: saveFile,

    } = useMutation(
        uploadAction(
            data?.transaction_id,
            data?.token
        ),
        {
            onSuccess: () => {
                setGlobalLoader(prev => prev - 1)
                globalLoader === 0 && history.push('/upload-confirmation')
            },
            onError: () => {
                setGlobalLoader(prev => prev - 1)
            }
        }
    );

    const { handleSubmit, control } = useForm({
        defaultValues: {},
    });

    useEffect(mutate, [mutate]);

    const onSubmit = async (values) => {
        setGlobalLoader(0)
        await values.dropbox.forEach(async (file) => {
            const base64 = await toBase64(file);
            const filename = file.name;
            await saveFile({ filename, base64: base64.split("base64,")[1] });
            setGlobalLoader(prev => prev + 1)
        })
    };

    const onError = () => {
        toast.warning("Dodaj plik przed wysłaniem")
    }

    if (data === null) {
        return <Loader fixed />;
    }

    return (
        <Layout showBasket={false} >
            {
                !!globalLoader && <Loader fixed={true} />
            }
            <Card>
                <Header>
                    Dokumenty dotyczące wniosku o wynajem
                </Header>
                <Desc>Prosimy o wgranie zaświadczenia o zatrudnieniu lub pobieraniu świadczeń  lub wyciąg z konta potwierdzający wypłatę wynagrodzenia.<br />Tu możesz także wgrać pełnomocnictwa do reprezentowania Najemcy.</Desc>

                <Form onSubmit={handleSubmit(onSubmit, onError)}>
                    <Controller
                        name='dropbox'
                        control={control}
                        defaultValue={[]}
                        rules={{ required: true }}
                        render={props => <DropBox props={props} />}
                    />
                    <Button type='submit' width={isMobile ? '100%' : 'initial'} onClick={() => false}>Wyślij dokumenty</Button>
                </Form>
            </Card>
        </Layout>
    );
};

export default ConfirmDocuments;
