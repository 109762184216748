import { createGlobalStyle } from "styled-components";
import { MOBILE_WIDTH, DESKTOP_CONTAINER_WIDTH } from "./variables";

export const theme = {
    primary: "#00ACED",
    secondary: "#2B3B8C",

    danger: "#FC1D26",

    black: "#000000",
    white: "#ffffff",
    grey: "#EAEBED",
    darkGrey: "#C8CACC",

    primaryGradient: "linear-gradient(to right, #2B3B8C, #00ACED)",
    dangerGradient: "linear-gradient(to right, #A51824, #FC1D26)",
    mobileWidth: `${MOBILE_WIDTH}px`,

    facebookColor: '#1976D2',
    linkedinColor: '#0077B7',
    containerWidth: `${DESKTOP_CONTAINER_WIDTH}px`,
};

export const GlobalStyle = createGlobalStyle`
    * {
        box-sizing: border-box;
        margin: 0;
        padding: 0;
        box-sizing: border-box;
    }
    a {
        text-decoration: none;
        color: black;
    }
    body {
        font-family: 'Roboto', sans-serif;
        font-size: 14px;
        background-color: #f7f7f7;
     
        
    }
    .grecaptcha-badge {
        display: none !important;
    }
`;
