import { useDispatch, useSelector } from "react-redux";
import styled, { css } from "styled-components";
import Layout from "../../components/Layout/Layout";
import EmailConfirm from "../../connectedComponents/EmailSend/EmailConfirm";
import { setLocation } from "../../features/basketSlice";
import LocationBox from "./components/LocationBox";
import SummaryBox from "./components/SummaryBox";
import { selectMobile } from '../../features/appSlice'
import { selectPickupPoints } from "../../features/basketSlice";
import { useEffect, useState } from "react";
import MainProduct from "./components/MainProduct";
import RecommendedAccesories from "./components/RecommendedAccesories";
import RecommendedServices from "./components/RecommendedServices";
import SelectedProductsList from "./components/SelectedProductsList";
import PeriodRow from "../../components/PeriodRow/PeriodRow";
import SocialBox from "../../components/SocialBox/SocialBox";
import CompanyRow from '../../components/CompanyRow/CompanyRow'
import RecommendedAccesoriesSlider from "./components/RecommendedAccesoriesSlider";
import RecommendedServicesSlider from "./components/RecommendedServicesSlider";
import Card from '../../components/Card/Card'
import SectionHeader from "../../components/SectionHeader/SectionHeader";
import NegativeContainer from "../../components/Container/NegativeContainer";


const BasketView = () => {
    const dispatch = useDispatch();
    const isMobile = useSelector(selectMobile)
    const pickupPoints = useSelector(selectPickupPoints);
    const [isDisableButton, setIsDisableButton] = useState(true)


    // Set 1st location from pickupPoint default, remove in future
    useEffect(() => {
        dispatch(setLocation(pickupPoints[0]));
        // eslint-disable-next-line
    }, [])

    return (
        <Layout showBasket={false} desktopTitle="Twój koszyk najmu">
            <Row isMobile={isMobile}>
                {
                    isMobile ? (
                        <>
                            <SectionHeader marginBottom={0}>Twój koszyk:</SectionHeader>
                            <BgWrapper isMobile={isMobile}>
                                <MainProduct />
                                {/* <CompanyRow /> */}
                                <PeriodRow />
                                <SelectedProductsList />
                            </BgWrapper>
                            <StyledNegativeContainer>
                                <RecommendedAccesoriesSlider />
                            </StyledNegativeContainer>
                            <StyledNegativeContainer>
                                <RecommendedServicesSlider />
                            </StyledNegativeContainer>
                            <LocationBox />
                            <Card>
                                <SummaryBox setIsDisableButton={setIsDisableButton} />
                            </Card>
                            <EmailConfirm isDisableButton={isDisableButton} />
                        </>
                    ) : (
                        <>
                            <Col1>
                                <BgWrapper>
                                    <MainProduct />
                                    <SelectedProductsList />
                                </BgWrapper>
                                <RecommendedAccesories />
                                <RecommendedServices />
                                <LocationBox />
                            </Col1>
                            <Col2>
                                <Col1Background>
                                    <CompanyRow />
                                    <PeriodRow />
                                    <SummaryBox setIsDisableButton={setIsDisableButton} />
                                    <EmailConfirm isDisableButton={isDisableButton} />
                                </Col1Background>
                                <SocialBox />
                            </Col2>
                        </>
                    )
                }
            </Row>
        </Layout>
    );
};

export default BasketView;


const Col1 = styled.div`
    flex: 5;
    display:flex;
    flex-direction: column;
    gap: 40px;
`

const Col2 = styled.div`
    flex: 3;
    display:flex;
    flex-direction: column;
    gap: 20px;
    border-radius: 20px;
`

const mobileStyle = css`
    flex-direction: column;
    ${Col1} {
        flex: 1;
    }
    ${Col2} {
        flex: 1;
    }
`

const Row = styled.div`
    display: flex;
    gap: 20px;
    ${props => props.isMobile && mobileStyle};
`

const Col1Background = styled.div`
    background-color: ${props => props.theme.white};
    display: flex;
    flex-direction: column;
    gap: 50px;
    padding: 20px;
    border-radius: 20px;
`

const BgWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 40px;
    padding: ${props => props.isMobile ? '10px' : '20px'};
    border-radius: 20px;
    background-color: ${props => props.theme.grey};
`

const StyledNegativeContainer = styled(NegativeContainer)`
    margin: 0px -15px 0px 0px; 
`
