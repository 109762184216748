import { yupResolver } from "@hookform/resolvers/yup";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import { toast } from "react-toastify";
import styled from "styled-components";
import * as yup from "yup";
import NavButton from "../../../components/Buttons/NavButton";
import Radio from "../../../components/Inputs/Radio";
import Loader from "../../../components/Loader/Loader";
import {
    selectStep1Data,
    selectTransactionId,
    setStep1
} from "../../../features/transactionSlice";
import Step1Service from "../../../services/Step1Service";
import Step2Service from "../../../services/Step2Service";
import RentInput, { ErrorContainer } from "../components/RentInput";
import RentLayout from "../components/RentLayout";
import RentSubtitle from "../components/RentSubtitle";
import RentTitle from "../components/RentTitle";

const SubtitleContainer = styled.div`
    margin-bottom: 28px;
`;
const FormContainer = styled.div`
    margin-top: 50px;
`;
const RadioContainer = styled.div`
    border: 1px solid
        ${({ theme, isError }) => (isError ? theme.danger : "transparent")};
    padding: 10px;
    margin: 10;
    label > span {
        font-weight: bold;
        font-size: 17px;
    }
`;

const NIP_ERROR =
    "Sprawdź, czy wprowadzony numer NIP jest prawidłowy. Wprowadź NIP jako ciąg liczb, bez spacji, myślników, ani innych dodatkowych znaków";

const LABELS = {
    nip: {
        LEGAL_PERSON: "NIP Spółki / Fundacji / Instytucji",
        INDIVIDUAL_ENTREPRENEUR: "NIP",
    },
    companyName: {
        LEGAL_PERSON: "Pełna nazwa Spółki / Fundacji / Instytucji",
        INDIVIDUAL_ENTREPRENEUR: "Pełna nazwa działalności gospodarczej",
    },
    firstName: {
        LEGAL_PERSON: "Imię osoby reprezentującej Najemcę",
        INDIVIDUAL_ENTREPRENEUR: "Imię",
        NATURAL_PERSON: "Imię",
    },
    lastName: {
        LEGAL_PERSON: "Nazwisko osoby reprezentującej Najemcę",
        INDIVIDUAL_ENTREPRENEUR: "Nazwisko",
        NATURAL_PERSON: "Nazwisko",
    },
    phone: {
        LEGAL_PERSON: "Telefon komórkowy osoby reprezentującej Najemcę",
        INDIVIDUAL_ENTREPRENEUR: "Numer telefonu",
        NATURAL_PERSON: "Numer telefonu",
    },
    personalNumber: {
        LEGAL_PERSON: "PESEL osoby reprezentującej Najemcę",
        INDIVIDUAL_ENTREPRENEUR: "PESEL",
        NATURAL_PERSON: "PESEL",
    },
    idCard: {
        LEGAL_PERSON:
            "Seria i numer dowodu osobistego osoby reprezentującej Najemcę",
        INDIVIDUAL_ENTREPRENEUR: "Numer i seria dowodu osobistego",
        NATURAL_PERSON: "Numer i seria dowodu osobistego",
    },
    address: {
        LEGAL_PERSON:
            "Adres zamieszkania osoby reprezentującej Najemcę",
        INDIVIDUAL_ENTREPRENEUR: "ADRES ZAMIESZKANIA",
        NATURAL_PERSON: "ADRES ZAMIESZKANIA",
    },
};

const MobileContractData = ({ navProps }) => {
    const schema = yup.object().shape({
        apartment: yup.string(),
        city: yup.string().required("Pole wymagane"),
        customerType: yup
            .string()
            .oneOf([
                "NATURAL_PERSON",
                "INDIVIDUAL_ENTREPRENEUR",
                "LEGAL_PERSON",
            ])
            .required("Pole wymagane"),
        firstName: yup.string().required("Pole wymagane"),
        idCard: yup.string().required("Pole wymagane"),
        lastName: yup.string().required("Pole wymagane"),
        personalNumber: yup.string().required("Pole wymagane"),
        phone: yup.string().required("Pole wymagane"),
        postCode: yup.string().required("Pole wymagane"),
        street: yup.string().required("Pole wymagane"),
        nip: yup
            .string()
            .when("customerType", {
                is: "INDIVIDUAL_ENTREPRENEUR",
                then: yup
                    .string(NIP_ERROR)
                    .min(10, "NIP jest za krótki. NIP powinien składać się z 10 cyfr")
                    .max(10, "NIP jest za długi. NIP powinien składać się z 10 cyfr")
                    .required("Pole wymagane"),
            })
            .when("customerType", {
                is: "LEGAL_PERSON",
                then: yup
                    .string(NIP_ERROR)
                    .min(10, "NIP jest za krótki. NIP powinien składać się z 10 cyfr")
                    .max(10, "NIP jest za długi. NIP powinien składać się z 10 cyfr")
                    .required("Pole wymagane"),
            })
    });
    const history = useHistory();

    const dispatch = useDispatch();

    const initialData = useSelector(selectStep1Data);
    const transactionId = useSelector(selectTransactionId);

    const {
        register,
        handleSubmit,
        control,
        watch,
        setError,
        getValues,
        clearErrors,
        formState: { errors },
    } = useForm({
        defaultValues: initialData,
        resolver: yupResolver(schema),
    });

    const { mutateAsync, isLoading } = useMutation(
        Step1Service.saveStep1(transactionId),
        {
            onError: (e) => {
                e.response.data?.errors?.forEach(
                    ({ message, invalid_property }) => {
                        setError(invalid_property, {
                            type: "manual",
                            message,
                        });
                    }
                );
                toast.error("Wpisano błędne dane");
            },
        }
    );

    const customerType = watch("customerType");

    useEffect(() => {
        if (customerType === "NATURAL_PERSON") {
            clearErrors("nip")
            // setValue("nip", "");
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customerType]);
    const nip = watch("nip");

    const {
        mutateAsync: checkNipMutation,
        data: nipData,
        isLoading: isNipLoading,
    } = useMutation(Step2Service.checkNip(nip), {
        onError: () => {
            setError("nip", {
                type: "manual",
                message: "Nie znaleziono numeru NIP",
            });
        },
    });

    const {
        mutateAsync: saveLegalPerson,
        isLoading: isLegalPersonLoading,
    } = useMutation(Step2Service.saveLegalPerson(transactionId));
    const {
        mutateAsync: saveIndividualEntrepreneur,
        isLoading: isIndividualEntrepreneuerLoading,
    } = useMutation(Step2Service.saveIndividualEntrepreneur(transactionId));

    useEffect(() => {
        if (nip?.length === 10) {
            checkNipMutation();
        }
    }, [checkNipMutation, nip]);

    const onSubmit = async (values) => {
        const {
            apartment,
            city,
            customerType,
            firstName,
            idCard,
            lastName,
            personalNumber,
            phone,
            postCode,
            street,
        } = values;

        const fields = {
            c2: firstName,
            c3: lastName,
            c4: personalNumber,
            c8: idCard,
            c17: phone,
            c10: street,
            c11: city,
            c12: postCode,
            c36: apartment,
            // c29: 1,
            c27: { c2: customerType },
            // c39: 0,
            // c45: 0,
        };

        if (customerType === "NATURAL_PERSON") {
            await mutateAsync(fields);
            dispatch(setStep1(values));
            history.push("/rent/financial-data");
        }
        if (customerType === "INDIVIDUAL_ENTREPRENEUR") {
            await mutateAsync(fields);
            await saveIndividualEntrepreneur({
                c5: nip,
            });
            dispatch(setStep1(values));
            // history.push("/rent/financial-data");
            history.push("/rent/online-application");
        }
        if (customerType === "LEGAL_PERSON") {
            await mutateAsync(fields);
            await saveLegalPerson({
                c5: nip,
            });
            history.push("/rent/online-application");
            dispatch(setStep1(values));
        }
    };
    const showLoader =
        isLoading || isLegalPersonLoading || isIndividualEntrepreneuerLoading;


    const handleCheckNIP = () => {
        const customer = getValues("customerType")
        if (customer !== "NATURAL_PERSON") {
            console.log('check')
            checkNipMutation()
        }
    }


    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <RentLayout {...navProps} showNav={false} showLoader={showLoader}>
                <RentTitle>Dane osobowe:</RentTitle>
                <SubtitleContainer>
                    <RentSubtitle>KTO WYNAJMUJE SPRZĘT?</RentSubtitle>
                </SubtitleContainer>
                <RadioContainer isError={!!errors?.customerType}>
                    <Controller
                        control={control}
                        name="customerType"
                        render={({ field: { onChange, value } }) => (
                            <Radio
                                value={value}
                                onChange={(e) => onChange(e)}
                                options={[
                                    {
                                        label: "Osoba fizyczna",
                                        value: "NATURAL_PERSON",
                                    },
                                    {
                                        label:
                                            "Jednoosobowa działalność gospodarcza",
                                        value: "INDIVIDUAL_ENTREPRENEUR",
                                    },
                                    {
                                        label: "Spółka / Fundacja / Instytucja",
                                        value: "LEGAL_PERSON",
                                    },
                                ]}
                            />
                        )}
                    />
                    <ErrorContainer>
                        {errors?.customerType?.message}
                    </ErrorContainer>
                </RadioContainer>
                <FormContainer>
                    <SubtitleContainer>
                        <RentSubtitle>DANE WNIOSKODAWCY:</RentSubtitle>
                    </SubtitleContainer>
                    {(customerType === "INDIVIDUAL_ENTREPRENEUR" ||
                        customerType === "LEGAL_PERSON") && (
                            <>
                                <RentInput
                                    label={LABELS.nip[customerType]}
                                    name="nip"
                                    error={errors.nip}
                                    register={register}
                                />
                                {isNipLoading && <Loader />}
                                {nipData?.c20 && (
                                    <RentInput
                                        label={LABELS.companyName[customerType]}
                                        value={nipData?.c20}
                                        disabled
                                    />
                                )}
                            </>
                        )}
                    <RentInput
                        label={
                            LABELS.firstName[
                            customerType || "INDIVIDUAL_ENTREPRENEUR"
                            ]
                        }
                        register={register}
                        name="firstName"
                        error={errors["c2"] || errors.firstName}
                    />

                    <RentInput
                        label={
                            LABELS.lastName[
                            customerType || "INDIVIDUAL_ENTREPRENEUR"
                            ]
                        }
                        register={register}
                        name="lastName"
                        error={errors["c3"] || errors.lastName}
                    />

                    <RentInput
                        label={
                            LABELS.phone[
                            customerType || "INDIVIDUAL_ENTREPRENEUR"
                            ]
                        }
                        description="Numer telefonu będzie potrzebny w trakcie realizacji transakcji do potwierdzenia warunków umowy oraz przy odbiorze sprzętu."
                        register={register}
                        name="phone"
                        error={errors["c17"] || errors.phone}
                    />
                    <RentInput
                        label={
                            LABELS.personalNumber[
                            customerType || "INDIVIDUAL_ENTREPRENEUR"
                            ]
                        }
                        description="Numer PESEL jest nam niezbędny do weryfikacji tożsamości Klienta wypożyczającego sprzęt."
                        register={register}
                        name="personalNumber"
                        error={errors["c4"] || errors.personalNumber}
                    />
                    <RentInput
                        label={
                            LABELS.idCard[
                            customerType || "INDIVIDUAL_ENTREPRENEUR"
                            ]
                        }
                        description="Numer dowodu osobistego jest niezbędny do identyfikacji Najemcy przy odbiorze sprzętu."
                        register={register}
                        name="idCard"
                        error={errors["c8"] || errors.idCard}
                    />
                </FormContainer>

                <FormContainer>
                    <SubtitleContainer>
                        <RentSubtitle>
                            {
                                LABELS.address[
                                customerType || "INDIVIDUAL_ENTREPRENEUR"
                                ]
                            }
                            :
                        </RentSubtitle>
                    </SubtitleContainer>
                    <RentInput
                        label="Ulica i numer budynku"
                        register={register}
                        name="street"
                        error={errors["c10"] || errors.street}
                    />
                    <RentInput
                        label="Numer lokalu"
                        register={register}
                        name="apartment"
                        error={errors["c36"] || errors.apartment}
                    />
                    <RentInput
                        label="Kod pocztowy"
                        register={register}
                        name="postCode"
                        error={errors["c12"] || errors.postCode}
                    />
                    <RentInput
                        label="Miejscowość"
                        register={register}
                        name="city"
                        error={errors["c11"] || errors.city}
                    />
                </FormContainer>

                <NavButton
                    backText='Twój koszyk najmu'
                    onNextClicked={handleCheckNIP}
                    backLink={"/basket"}
                    submit
                    disabled={showLoader}
                // disabled={
                //     showLoader ||
                //     !isValid ||
                //     ((customerType === "INDIVIDUAL_ENTREPRENEUR" ||
                //         customerType === "LEGAL_PERSON") &&
                //         !nipData)
                // }
                />
            </RentLayout>
        </form>
    );
};

export default MobileContractData;
