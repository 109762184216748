import styled from "styled-components";

const Root = styled.span`
    display: inline-block;
    font-size: 0.6428571428571429em;
    padding: 5px 8px;
    border-radius: 50px;
    color: ${({ theme }) => theme.white};
    background: ${({ background }) => background};
    font-weight: 900;
    letter-spacing: 0.025em;
    text-transform: uppercase;
`;

const TYPE_COLORS = {
    primary: "linear-gradient(to right, #0486D6, #03ADEF)",
    danger: "linear-gradient(to right, #A51824, #FC1D26)",
};
const Badge = ({ children = "Dostępny", type = "primary" }) => (
    <Root style={{fontSize:10}} background={TYPE_COLORS[type]}>{children}</Root>
);

export default Badge;
