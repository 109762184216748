import { createSlice } from "@reduxjs/toolkit";

const PERIOD_VALUES = [1, 3, 6, 12, 24];

const initialState = {
    mainProduct: null,
    additionalProducts: [],
    services: [],
    location: null,
    isCompany: false,
    minimal_months: 4,
    real_months: 1,
    shop_uuid: null,
    createdDate: null,
    email: null,
    category: null
};

export const basketSlice = createSlice({
    name: "basket",
    initialState,
    reducers: {
        setEmail: (state, action) => {
            state.email = action.payload;
        },
        setShopUuid: (state, action) => {
            state.shop_uuid = action.payload;
        },
        setMainProduct: (state, action) => {
            state.additionalProducts = [];
            state.services = [];
            state.isCompany = false;
            state.minimal_months = 4;
            state.real_months = 1;

            state.mainProduct = action.payload;
            state.createdDate = Date.now();
        },
        clearBasket: (state) => {
            state.additionalProducts = [];
            state.services = [];
        },
        addAdditionalProduct: (state, action) => {
            state.additionalProducts = [
                ...state.additionalProducts,
                action.payload,
            ];
        },
        removeAdditionalProduct: (state, action) => {
            state.additionalProducts = state.additionalProducts.filter(product => product.id !== action.payload);
        },
        addService: (state, action) => {
            state.services = [...state.services, action.payload];
        },
        removeService: (state, action) => {
            state.services = state.services.filter(service => service.id !== action.payload);
        },
        setCompany: (state, action) => {
            state.isCompany = action.payload;
        },

        setRealMonths: (state, action) => {
            state.real_months = action.payload;
        },
        setMinimalMonths: (state, action) => {
            state.minimal_months = action.payload;
        },
        setLocation: (state, action) => {
            state.location = action.payload;
        },
        setCategory: (state, action) => {
            state.category = action.payload;
        },
        reset: (state, action) => {
            state.mainProduct = initialState.mainProduct;
            state.additionalProducts = initialState.additionalProducts;
            state.services = initialState.services;
            state.location = initialState.location;
            state.isCompany = initialState.isCompany;
            state.minimal_months = initialState.minimal_months;
            state.real_months = initialState.real_months;
            state.shop_uuid = initialState.shop_uuid;
            state.createdDate = initialState.createdDate;
            state.email = initialState.email;
        }

    },
});

export const selectMainProduct = (state) => state.basket.mainProduct;
export const selectShopUuid = (state) => state.basket.shop_uuid;
export const selectAdditionalProducts = (state) =>
    state.basket.additionalProducts;
export const selectServices = (state) => state.basket.services;

export const selectServicesBefore = (state) =>
    state.basket.services.filter((s) => s.type === "services_before");
export const selectServicesDuring = (state) =>
    state.basket.services.filter((s) => s.type === "services_during");

export const selectIsCompany = (state) => state.basket.isCompany;

export const selectRealMonths = (state) => state.basket.real_months;
export const selectRealMonthsParsed = (state) => state.basket.real_months;

export const selectMinimalMonths = (state) => state.basket.minimal_months;
export const selectMinimalMonthsParsed = (state) =>
    PERIOD_VALUES[state.basket.minimal_months];

export const selectLocation = (state) => state.basket.location;
export const selectPickupPoints = (state) =>
    state.basket.mainProduct.localizations;

export const selectOrderProducts = (state) => {
    const parseObject = ({ id, gross_price, net_price }) => ({
        id,
        gross_price,
        net_price,
        quantity: 1,
    });

    const mainProduct = state.basket.mainProduct;

    const additionalProducts = state.basket.additionalProducts
        ? state.basket.additionalProducts.map(parseObject)
        : [];

    const services_before_items = state.basket.services
        ? state.basket.services
            .filter((s) => s.type === "services_before")
            .map(parseObject)
        : [];

    const services_during_items = state.basket.services
        ? state.basket.services
            .filter((s) => s.type === "services_during")
            .map(parseObject)
        : [];

    return {
        main_items: [
            {
                quantity: 1,
                id: mainProduct.id,
                gross_price: mainProduct.gross_price,
                net_price: mainProduct.net_price,
            },
        ],
        accessories_items: additionalProducts,
        services_before_items,
        services_during_items,
    };
};
export const selectBasketCreatedDate = (state) => state.createdDate;
export const email = (state) => state.basket.email
export const selectCategory = (state) => state.basket.category

// Action creators are generated for each case reducer function
export const {
    setMainProduct,
    setShopUuid,
    clearBasket,
    addAdditionalProduct,
    removeAdditionalProduct,
    addService,
    removeService,
    setCompany,
    setPeriod,
    setMinimalMonths,
    setRealMonths,
    setLocation,
    reset,
    setEmail,
    setCategory,
} = basketSlice.actions;

export default basketSlice.reducer;
