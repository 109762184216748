import { useSelector } from "react-redux";
import { Redirect, Route, Switch } from "react-router";
import styled from "styled-components";
import Container from "../../components/Container/Container";
import Header from "../../components/Header/Header";
import { selectTransactionData } from "../../features/transactionSlice";
import { MOBILE_WIDTH } from "../../variables";
import NotFound from "../NotFound/NotFound";
import ContractData from "./ContractData/ContractData";
import FinancialData from "./FinancialData/FinancialData";
import G4_Confirmation from "./G4_Confirmation/G4_Confirmation";
import OnlineApplication from "./OnlineApplication/OnlineApplication";
import OnlineContract from "./OnlineContract/OnlineContract";
import OnlinePayment from "./OnlinePayment/OnlinePayment";
import { selectDesktop } from '../../features/appSlice'
import DesktopFooter from "../../components/DesktopFooter/DesktopFooter";

export const RENT_ROUTES = [
    {
        path: "/rent/contract-data",
        label: "Dane do umowy",
        Component: ContractData,
    },
    {
        path: "/rent/financial-data",
        label: "Dane finansowe",
        Component: FinancialData,
    },
    {
        path: "/rent/online-application",
        label: "Wniosek online",
        Component: OnlineApplication,
    },
    {
        path: "/rent/g4-confirmation",
        label: "Potwierdzenie G4.Rent",
        Component: G4_Confirmation,
    },
    {
        path: "/rent/online-contract",
        label: "Umowa online",
        Component: OnlineContract,
    },
    {
        path: "/rent/online-payment",
        label: "Płatność online",
        Component: OnlinePayment,
    }
];


const Layout = styled.div`
    min-height: 100vh;
    
    @media screen and (max-width: ${MOBILE_WIDTH}px) {
        background-color: ${({ theme }) => theme.white};
    }
`;
const NavContainer = styled.div`
    margin-top: 18px;
`;

const RentSteps = () => {
    const transactionData = useSelector(selectTransactionData);
    const isDesktop = useSelector(selectDesktop)


    if (!transactionData) {
        return <Redirect to="/data-error" />;
    }
    return (
        <Layout isDesktop={isDesktop}>
            <Header showBasket={false} />
            <Container>
                <NavContainer>
                    <Switch>
                        {
                            RENT_ROUTES.map(({ path, Component }, index) => (
                                <Route path={path} key={path}>
                                    <Component
                                        navProps={{
                                            activeStep: index,
                                            nextStep: RENT_ROUTES[index + 1]?.path,
                                            prevStep: RENT_ROUTES[index - 1]?.path,
                                        }}
                                    />
                                </Route>
                            ))
                        }
                        <Route path="*" component={NotFound} />
                    </Switch>
                </NavContainer>
            </Container>
            <DesktopFooter/>
        </Layout>
    );
};

export default RentSteps;
