import styled from "styled-components";
import CollapseIcon from "../../Collapse/CollapseIcon";
import DataRow from "../../DataRow/DataRow";
import AlertIcon from "../../Icons/components/AlertIcon";
import CurrencyIcon from "../../Icons/components/CurrencyIcon";
import MapPinIcon from "../../Icons/components/MapPinIcon";

const Root = styled.div`
    margin: 4px 0 22px;
    display: flex;
    flex-direction: ${props => props.flexDirection};
    flex-wrap: ${props => props.flexWrap};
`;
const Row = styled.div`
    margin-bottom: 8px;
      &:not(:last-child) {
        margin-right: ${props => props.gap};
    }
`;
const SmallText = styled.span`
    font-weight: 400;
    font-size: 14px;
`;

const FeaturesText = styled.span`
    font-size: 17px;
`;


const ProductDetails = ({ category, installment, extraContent, openDefault = false, location, condition, price, description, service = false, flexDirection = 'column', flexWrap = 'nowrap', gap = '0' }) => {

    return (
        <Root flexDirection={flexDirection} flexWrap={flexWrap}>
            {
                installment && (
                    <Row gap={gap}>
                        <DataRow
                            Icon={<MapPinIcon />}
                            label={<FeaturesText>Rata miesięczna:</FeaturesText>}
                            value={<FeaturesText>{`${installment} zł brutto`}</FeaturesText>}
                        />
                    </Row>
                )
            }
            {location && (
                <Row gap={gap}>
                    <DataRow
                        Icon={<MapPinIcon />}
                        label={<FeaturesText>Lokalizacja:</FeaturesText>}
                        value={<FeaturesText>{location}</FeaturesText>}
                    />
                </Row>
            )}
            {condition && (
                <Row gap={gap}>
                    <DataRow
                        Icon={<AlertIcon />}
                        label={<FeaturesText>Stan:</FeaturesText>}
                        value={<FeaturesText>{condition}</FeaturesText>}
                    />
                </Row>
            )}
            {category !== 'all_services' && price && parseInt(price) > 0 && (
                <Row gap={gap}>
                    <DataRow
                        Icon={<CurrencyIcon />}
                        label={<FeaturesText>{"Wartość sprzętu:"}</FeaturesText>}
                        value={<FeaturesText>{`${price} zł brutto`}</FeaturesText>}
                    />
                </Row>
            )}
            {description && (
                <Row>
                    <CollapseIcon
                        openDefault={openDefault}
                        label={
                            <span>
                                <FeaturesText>Opis</FeaturesText> <SmallText>{'(pokaż/ukryj)'}</SmallText>
                            </span>
                        }
                        description={
                            <FeaturesText dangerouslySetInnerHTML={{ __html: description }} />
                        }
                        extraContent={extraContent}
                    ></CollapseIcon>
                </Row>
            )}
        </Root>
    )
}

export default ProductDetails



