import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import MessangerIcon from '../Icons/components/MessangerIcon'
import QuestionMarkIcon from '../Icons/components/QuestionMarkIcon'


const SocialBox = () => {
    return (
        <StyledSocialBox>
            <Box>
                <StyledNoramlLink href="https://www.facebook.com/messages/t/623742458256865">
                    <MessangerIcon className='icon' width={20} height={20} />
                    <Text>
                    Porozmawiaj z nami
                    </Text>
                </StyledNoramlLink>
                <StyledLink to="/how-it-works">
                    <QuestionMarkIcon className='icon' width={20} height={20} />
                    <Text>
                        Sprawdź jak to działa
                    </Text>
                </StyledLink>
            </Box>
        </StyledSocialBox>
    )
}

export default SocialBox

const StyledSocialBox = styled.div`
    display: flex;
    justify-content: center;
    padding: 40px 0px;
    .icon {
        color: ${props => props.theme.primary};
    }
`

const Box = styled.div`
    display: flex;
    flex-direction: column;
    gap: 10px;
`


const Text = styled.div`
    font-size: 17px;
    text-decoration: underline;
`

const StyledLink = styled(Link)`
    display: flex;
    align-items: center;
    gap: 10px;
`
const StyledNoramlLink = styled.a`
    display: flex;
    align-items: center;
    gap: 10px;
`

